import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import { format } from "date-fns";
import { Link } from "@quintype/components";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { getSubscriptionURL, generateImageSources, pdfIssueDateFormat } from "../../utils/utils";
import styles from "./styles.m.css";
import Icon from "../icon";

import { WebEngage, DeepBI, EVOLOK, GA } from "../../../integrations";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const MagazineIssueCardBase = ({
  collection,
  prevIssue,
  nextIssue,
  magazine,
  className = "",
  cardWithImageZoom = true,
  subscriptionHost = "",
  config
}) => {
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");
  const issueDate = get(collection, ["collection-date"]);
  const magazineType = get(collection, ["magazineType"]);
  const magazineName = get(collection, ["magazineEnName"]);
  const mName = get(collection, ["name"]);
  const mID = get(magazine, ["id"]);
  const magazineLink = get(config, ["magazines"], []).find(m => m.id === mID);
  const [evAccess, setEvAccess] = useState("RQUIRED_LOGIN");

  useEffect(() => {
    EVOLOK.loadScript("magazine-page", { collection: collection }, handleMeteringSuccess);
  }, []);

  const handleMeteringSuccess = response => {
    setEvAccess(response.result);
  };

  const linkName = magazineName === "Motor Vikatan English" ? "Download Now" : "Buy Now";
  const downloadLink = `https://image.vikatan.com/mvenglish/pdf/${format(new Date(issueDate), "yyyyMMdd")}.pdf`;

  const handleEvent = () => {
    const id = cookies.get("vuid") || cookies.get("thinmint");
    GA.customEvent("magazineDownload", format(new Date(issueDate), "dd-MM-yyyy"), id);
  };

  const [isShown, setIsShown] = useState(false);

  const popupClick = event => {
    const issueDate = get(collection, ["collection-date"]);

    const formattedIssueDate = `${pdfIssueDateFormat(issueDateOne, "custom")}`;
    const magazinePdfPagePath = `https://www.vikatan.com/pdf-preview${magazineLink.url}/${formattedIssueDate}`;
    window.open(magazinePdfPagePath, "_blank");
    DeepBI.PingPDF(collection, mName, issueDate, mID);
    WebEngage.WebengageWebPDFView(collection, mName, issueDate, mID);
  };

  const showPopupClick = () => {
    setIsShown(!isShown);
  };
  // const formattedMagazineName = magazineName.toLowerCase().replace(/\s/g, "");
  // const formattedDate = new Date(issueDate);
  // const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
  // const formattedIssueDate = `${formattedDate.getDate()}-${
  //   months[formattedDate.getMonth()]
  // }-${formattedDate.getFullYear()}`;
  // const magazinePdfPagePath = `/pdf-preview/${formattedMagazineName}/${formattedIssueDate}`;

  const issueDateOne = get(collection, ["collection-date"]);

  const formattedIssueDate = `${pdfIssueDateFormat(issueDateOne, "custom")}`;
  const magazinePdfPagePath = `https://www.vikatan.com/pdf-preview${magazineLink.url}/${formattedIssueDate}`;

  const switchMonth = 9 + 2023; // September
  const newDate = new Date(issueDate);
  const currentMonth = newDate.getMonth() + 1 + newDate.getFullYear();
  const showReadInBookButton =
    newDate.getFullYear() > 2023 || (newDate.getFullYear() === 2023 && newDate.getMonth() >= 8);
  let buyLink;

  if (currentMonth < switchMonth) {
    buyLink = magazinePdfPagePath;
  } else {
    buyLink = getSubscriptionURL(subscriptionHost, collection);
  }

  const generateURL = () => {
    const isMotorVikatanEnglish = magazineName === "Motor Vikatan English";

    if (isMotorVikatanEnglish) {
      return downloadLink;
    }

    if (magazineName !== "Vikatan Select") {
      return collection.pagePath;
    }

    if (evAccess !== "ALLOW_ACCESS" && magazineType) {
      return buyLink;
    }

    if (showReadInBookButton) {
      return magazinePdfPagePath;
    }

    if (evAccess === "ALLOW_ACCESS") {
      popupClick();
    }

    return collection.pagePath;
  };

  const magazineDoctorVikatan = magazineName === "Doctor Vikatan";

  const magazineVikatanSpecials = magazineName === "Vikatan Specials";

  return (
    <div className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <div className={styles["image-container"]}>
        <a href={generateURL}>
          <ResponsiveImageWithFallback
            className={`${styles["image-wrapper"]} qt-figure`}
            slug={coverImageS3Key}
            metadata={coverImageMetaData}
            alt={imgAltText}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [3, 4], screenWidthCoverage: 0.95 },
              { aspectRatio: [3, 4], screenWidthCoverage: 0.32 }
            )}
          />
        </a>
      </div>

      {issueDate ? <div className={styles["published-date-range"]}>{pdfIssueDateFormat(issueDate)}</div> : null}

      {magazineName === "Motor Vikatan English" ? (
        <a
          onClick={() => handleEvent()}
          href={downloadLink}
          className={styles["buy-btn"]}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkName}
        </a>
      ) : showReadInBookButton && !magazineDoctorVikatan && !magazineVikatanSpecials ? (
        <div>
          {magazineName !== "Vikatan Select" ? (
            <div>
              <a
                className={`${styles["buy-btn"]} ${styles["read-book-format"]} hidden-mobile`}
                rel="noopener noreferrer"
                target="_blank"
                onClick={popupClick}
              >
                <div className={styles["new-tag"]}>
                  <img
                    src="https://media.vikatan.com/vikatan/2023-11/01c5dc9d-b1fe-475f-9a3b-6522b51a9a71/new_pdf.png"
                    alt=""
                  />
                </div>
                <Icon type="pdf" className={styles["book-icon"]} />
                <span className={styles["book-format-txt"]}>Read in Book Format</span>
              </a>
              <div className="hidden-desktop">
                <a
                  className={`${styles["buy-btn"]} ${styles["read-book-format"]}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={showPopupClick}
                >
                  <Icon type="pdf" className={styles["book-icon"]} />
                  <span className={styles["book-format-txt"]}>Read in Book Format</span>
                </a>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div>
          <div />

          {isShown && (
            <div className={styles["customStyles-wrapper"]}>
              <div className={styles["customStyles"]}>
                <img src="https://media.vikatan.com/vikatan/2022-10/d90a3e94-d1eb-4d52-8cc6-b7b949ba80ca/magazine_pdf.png" />
                <div>
                  <strong>
                    இந்த இதழை விகடன் App-ல் மட்டுமே Book Format-ல் படிக்க முடியும். App-ஐ இன்ஸ்டால் செய்கிறீர்களா?
                  </strong>
                  <div className={styles["popup-btns"]}>
                    <a
                      href="#"
                      className={`${styles["buy-btn"]} ${styles["secondary-buy-btn"]}`}
                      onClick={showPopupClick}
                    >
                      No
                    </a>
                    <a href="https://vikatanmobile.page.link/ReadInBookFormat" className={`${styles["buy-btn"]}`}>
                      Install Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isShown && (
        <div className={styles["customStyles-wrapper"]}>
          <div className={styles["customStyles"]}>
            <img src="https://media.vikatan.com/vikatan/2022-10/d90a3e94-d1eb-4d52-8cc6-b7b949ba80ca/magazine_pdf.png" />
            <div>
              <strong>
                இந்த இதழை விகடன் App-ல் மட்டுமே Book Format-ல் படிக்க முடியும். App-ஐ இன்ஸ்டால் செய்கிறீர்களா?
              </strong>
              <div className={styles["popup-btns"]}>
                <a href="#" className={`${styles["buy-btn"]} ${styles["secondary-buy-btn"]}`} onClick={showPopupClick}>
                  No
                </a>
                <a href="https://vikatanmobile.page.link/ReadInBookFormat" className={`${styles["buy-btn"]}`}>
                  Install Now
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <ul className={styles["pager"]}>
        <li className={`${styles["pager-item"]} ${styles["pager-left-item"]} ${prevIssue ? "" : styles.disabled}`}>
          <Link
            href={prevIssue ? prevIssue["pagePath"] : undefined}
            className={`${styles["pager-link"]} ${prevIssue ? "" : styles.disabled}`}
          >
            <Icon type="angle-left" className={styles["pager-icon"]} />
          </Link>
        </li>
        <li className={`${styles["pager-item"]} ${styles["pager-right-item"]} ${nextIssue ? "" : styles.disabled}`}>
          <Link
            href={nextIssue ? nextIssue["pagePath"] : undefined}
            className={`${styles["pager-link"]} ${nextIssue ? "" : styles.disabled}`}
          >
            <Icon type="angle-right" className={styles["pager-icon"]} />
          </Link>
        </li>
      </ul>
    </div>
  );
};

MagazineIssueCardBase.propTypes = {
  className: PropTypes.string,
  subscriptionHost: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  collection: PropTypes.shape({
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        }),
        "cover-image-s3-key": PropTypes.string
      }),
      magazine: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  prevIssue: PropTypes.object,
  nextIssue: PropTypes.object,
  magazine: PropTypes.number
};

export const MagazineIssueCard = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(MagazineIssueCardBase);

export default MagazineIssueCard;
